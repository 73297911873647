import { Container, Divider, Button, Card, Box, Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ModalCreateItem } from './ModalCreateItem';
import useTabs from 'src/hooks/useTabs';
import { capitalCase } from 'change-case';
import ItemTab from './ItemTab';
import ItemBundleTab from './ItemBundleTab';

export default function Items() {
  const { themeStretch } = useSettings();

  // tabs
  const { currentTab, onChangeTab } = useTabs('Materiais');

  const ACCOUNT_TABS = [
    {
      value: 'Materiais',
      component: <ItemTab />,
    },
    {
      value: 'Blocos de Materiais',
      component: <ItemBundleTab />,
    },
  ];

  return (
    <Page title="Materiais">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Materiais"
          links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Materiais' }]}
        />

        <Divider />

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} value={tab.value} />
          ))}
        </Tabs>

        <Card>
          {ACCOUNT_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Card>
      </Container>
    </Page>
  );
}
