// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    account: path(ROOTS_DASHBOARD, '/account'),
  },
  management: {
    users: path(ROOTS_DASHBOARD, '/management/users'),
    createUsers: path(ROOTS_DASHBOARD, '/management/users/create'),
    editUser: (id: string) => path(ROOTS_DASHBOARD, `/management/users/${id}/edit`),

    admins: path(ROOTS_DASHBOARD, '/management/admins'),
    createAdmin: path(ROOTS_DASHBOARD, '/management/admins/create'),
    editAdmin: (id: string) => path(ROOTS_DASHBOARD, `/management/admins/${id}/edit`),

    plans: path(ROOTS_DASHBOARD, '/management/plans'),
    createPlan: path(ROOTS_DASHBOARD, '/management/plans/create'),
    editPlan: (id: string) => path(ROOTS_DASHBOARD, `/management/plans/${id}/edit`),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },

  metrics: path(ROOTS_DASHBOARD, '/metrics'),
  tickets: path(ROOTS_DASHBOARD, '/tickets'),

  items: path(ROOTS_DASHBOARD, '/items'),
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
