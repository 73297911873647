import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

import ReactApexChart, { Props as reactApexChartsProps } from 'react-apexcharts';

interface Props {
  data: string[];
}

export const ProjectCreationDateChart = ({ data }: Props) => {
  const dateCounts: { [date: string]: number } = {};

  function compareDates(a: string, b: string): number {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA.getTime() - dateB.getTime();
  }

  data.sort(compareDates);

  data.forEach((dateString) => {
    const date = new Date(dateString).toISOString().split('T')[0];
    dateCounts[date] = (dateCounts[date] || 0) + 1;
  });

  // Encontrar a menor e a maior data na lista
  const dates = Object.keys(dateCounts);
  const startDate = new Date(Math.min(...dates.map((date) => new Date(date).getTime())));
  const endDate = new Date(Math.max(...dates.map((date) => new Date(date).getTime())));

  // Criar um objeto para todas as datas entre a menor e a maior data
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    const dateString = currentDate.toISOString().slice(0, 10); // Formato 'yyyy-mm-dd'
    dateCounts[dateString] = dateCounts[dateString] || 0;
    currentDate.setDate(currentDate.getDate() + 1); // Avançar para o próximo dia
  }

  const chartData: reactApexChartsProps = {
    options: {
      chart: {
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      xaxis: {
        tickAmount: 4,
        tickPlacement: 'between',
        type: 'datetime',
        categories: Object.keys(dateCounts).sort(compareDates),
        labels: {
          formatter: function (value, timestamp) {
            return format(new Date(value), 'dd/MM/yy');
          },
        },
      },
      yaxis: {
        title: {
          text: 'Contagem',
        },
        decimalsInFloat: 0,
        min: 0,
      },
    },
    series: [
      {
        name: 'Projetos',
        data: Object.keys(dateCounts)
          .sort(compareDates)
          .map((date) => ({
            x: date,
            y: dateCounts[date],
          })),
      },
    ],
  };

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height={350}
    />
  );
};
