import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

interface ModalConfirmationsProps {
  title: string;
  description: string;
  open: boolean;
  handleClose: () => void;
  execute: () => void;
}

export function ModalConfirmations({
  title,
  description,
  open,
  handleClose,
  execute,
}: ModalConfirmationsProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cancelar</Button>
        <LoadingButton onClick={execute} autoFocus>
          confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
