import * as Yup from 'yup';

export const NewTicketSchema = Yup.object().shape({
  description: Yup.string().nullable(),
  duration: Yup.string().required('O Ciclo de Desconto é obrigatório!'),
  duration_in_months: Yup.number().when('duration', {
    is: 'repeating', // quando o valor de 'duration' for 'repeating'
    then: Yup.number()
      .required('A duração em meses é necessária para durações repetidas.')
      .integer('Deve ser informado um número Inteiro.'),
    otherwise: Yup.number().nullable(),
  }),
  id: Yup.string()
    .nullable()
    .matches(
      /^[a-zA-Z0-9]+$/,
      'O campo deve começar com uma letra ou número e conter apenas letras e números'
    ),
  percent_off: Yup.number().required('O percentual de desconto é obrigatório!').nullable(),
  quantity: Yup.number().integer('Deve ser informado um número Inteiro.').nullable(),
  redeem_by: Yup.date().nullable().min(new Date(), 'A data de resgate deve ser uma data futura.'),
});
