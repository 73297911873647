import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  TextField,
  Stack,
  useTheme,
  Typography,
  Select,
  FormHelperText,
  MenuItem,
  TableContainer,
  TableBody,
  Table,
  TablePagination,
} from '@mui/material';
import axiosInstance from 'src/utils/axios';
import { FormikProvider, useFormik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Item, ItemBundle, ItemGroup, itemQuantity } from 'src/@types/localEntity';
import Iconify from 'src/components/Iconify';
import { apiRoutes } from 'src/routes/api/index';
import { EditPlanSchema, NewPlanSchema } from 'src/sections/@dashboard/itemBundle/validations';
import useTable from 'src/hooks/useTable';
import Scrollbar from 'src/components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData } from 'src/components/table';
import ItemTableRowQuantitySelection from 'src/sections/@dashboard/items/list/ItemTableRowQuantitySelection';
import { ItemTableToolbar } from 'src/sections/@dashboard/items/list';

interface FormValuesProps {
  description: string;
  observation: string;
  group_id: string;
  items: itemQuantity[];
}

interface ModalCreateItemBundleProps {
  open: boolean;
  handleClose: () => void;
  reQuery: () => void;
  currentItemBundle?: ItemBundle;
  id?: string;
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'description', label: 'Descrição', align: 'left' },
  { id: 'specification', label: 'Especificação', align: 'left' },
  { id: 'complement', label: 'Complemento', align: 'left' },
  { id: 'reference', label: 'Referência', align: 'left' },
  { id: 'group', label: 'Grupo de materiais', align: 'left' },
  { id: 'unit', label: 'Unid.', align: 'left' },
  { id: '' },
];
// ----------------------------------------------------------------------
export function ModalCreateItemBundle({
  //description,
  open,
  handleClose,
  reQuery,
  currentItemBundle,
  id,
}: ModalCreateItemBundleProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [groups, setGroups] = useState<ItemGroup[]>([]);

  const queryGroups = async () => {
    await axiosInstance
      .get(apiRoutes.items.readGroups())
      .then((data) => {
        setGroups(data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const isEdit = currentItemBundle && id;

  const theme = useTheme();
  const formik = useFormik<FormValuesProps>({
    enableReinitialize: true,
    initialValues: {
      description: currentItemBundle?.description || '',
      observation: currentItemBundle?.observation || '',
      group_id: currentItemBundle?.group_id || '',
      items: [],
    },
    validationSchema: !isEdit ? NewPlanSchema : EditPlanSchema,
    onSubmit: async (values: FormValuesProps, { resetForm, setErrors }) => {
      const data = {
        description: values.description,
        observation: values.observation,
        group_id: values.group_id,
        items: values.items,
      };

      if (!isEdit) {
        await axiosInstance
          .post(apiRoutes.itemBundles.create(), data)
          .then((data) => {
            enqueueSnackbar('Bloco criado com sucesso!');
            reQuery();
          })
          .catch((error) => {
            console.error(error);
            enqueueSnackbar(error.message, { variant: 'error' });
          });
        handleClose();
        resetForm();
        setItemQuantity([]);
      } else if (isEdit && id) {
        await axiosInstance
          .patch(apiRoutes.itemBundles.update(id), data)
          .then((data) => {
            enqueueSnackbar('Bloco atualizado com sucesso!');
            reQuery();
          })
          .catch((error) => {
            console.error(error);
            enqueueSnackbar(error.message, { variant: 'error' });
          });
        handleClose();
        resetForm();
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  useEffect(() => {
    if (isEdit && currentItemBundle) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentItemBundle]);

  const {
    dense,
    page,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const [tableData, setTableData] = useState<Item[]>([]);
  const [tableDataLength, setTableDataLength] = useState(0);

  const [filterName, setFilterName] = useState('');

  const queryItems = async () => {
    const items = await axiosInstance.get(apiRoutes.items.read(page, rowsPerPage, filterName));

    if (items) {
      setTableData(items?.data.items);
      setTableDataLength(items?.data.count);
    }
  };

  useEffect(() => {
    queryItems();
  }, [filterName, page]);

  useEffect(() => {
    queryGroups();
  }, []);

  // table functions
  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound = !tableData.length && !!filterName;

  // ItemQuantity

  const [ItemQuantity, setItemQuantity] = useState<itemQuantity[]>(
    isEdit
      ? currentItemBundle.items_relation.map((itemRelation) => {
          return {
            id: itemRelation.item_id,
            quantity: itemRelation.quantity,
          };
        })
      : []
  );

  const [selectedTableData, setSelectedTableData] = useState<Item[]>(
    isEdit ? currentItemBundle.items_relation.map((itemRelation) => itemRelation.item) : []
  );

  const handleChangeItemQuantity = (id: string, newQuantity: number) => {
    if (newQuantity < 0) {
      return;
    }

    let newArray: itemQuantity[] = ItemQuantity;

    const findItemIndex = newArray.findIndex((item) => item.id === id);

    if (findItemIndex !== -1) {
      newArray[findItemIndex].quantity = newQuantity;
    } else if (newQuantity === 0) {
      newArray = newArray.filter((item) => item.id !== id);
      setSelectedTableData((prev) => prev.filter((item) => item.id !== id));
    } else {
      newArray = [...ItemQuantity, { id, quantity: newQuantity }];

      // adiciona em uma segunda tabela idependente
      const tableItem = tableData.find((item) => item.id === id);
      if (tableItem) setSelectedTableData((prev) => [...prev, tableItem]);
    }

    newArray = newArray.filter((item) => item.quantity > 0);

    setItemQuantity(newArray);
  };

  useEffect(() => {
    formik.setFieldValue('items', ItemQuantity);
  }, [ItemQuantity]);

  const getItemQuantity = (id: string) => {
    return ItemQuantity.find((item) => item.id === id)?.quantity || 0;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        {currentItemBundle ? `Editar bloco de materiais` : `Cadastrar bloco de materiais`}
        <div
          style={{
            borderRadius: '3px',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.grey[300],
            boxShadow: '0px 1px 2px rgba(145, 158, 171, 0.24)',
            cursor: 'pointer',
          }}
          onClick={() => handleClose()}
        >
          <Iconify icon="material-symbols:close" fontSize="10px" color={theme.palette.grey[600]} />
        </div>
      </DialogTitle>
      <DialogContent style={{ marginTop: '20px' }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box>
              <Grid container spacing={2} sx={{ marginBottom: '15px' }}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Descrição
                  </Typography>
                  <TextField
                    placeholder="Ex.: Captor tipo Franklin com 4 pontas em latão cromado, com 1 descida, altura de 250 a 300mm, montado em mastro simples de AG, DN 1.1/2” e h =3 m"
                    fullWidth
                    multiline
                    minRows={3}
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Grupo de materiais
                  </Typography>
                  <Select
                    fullWidth
                    {...getFieldProps('group_id')}
                    error={Boolean(touched.group_id && errors.group_id)}
                  >
                    {groups.map((group) => {
                      return (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {touched.group_id && errors.group_id && (
                    <FormHelperText>{errors.group_id}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Adicionar materiais ao bloco
                  </Typography>
                  <Grid item xs={12} md={6}>
                    <ItemTableToolbar
                      filterName={filterName}
                      onFilterName={handleFilterName}
                      searchLabel="Buscar por um material..."
                    />
                  </Grid>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                      <Table size={dense ? 'small' : 'medium'}>
                        <TableHeadCustom
                          headLabel={TABLE_HEAD}
                          rowCount={tableData.length}
                          numSelected={selected.length}
                          onSort={onSort}
                        />

                        <TableBody>
                          {tableData.map((row) => (
                            <ItemTableRowQuantitySelection
                              key={row.id}
                              row={row}
                              quantity={getItemQuantity(row.id)}
                              setQuantity={handleChangeItemQuantity}
                            />
                          ))}

                          <TableEmptyRows
                            height={denseHeight}
                            emptyRows={rowsPerPage - tableData.length}
                          />

                          <TableNoData isNotFound={isNotFound} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={tableDataLength}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={onChangePage}
                      onRowsPerPageChange={onChangeRowsPerPage}
                      labelRowsPerPage="Linhas por página"
                    />
                  </Scrollbar>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Materiais relacionados a esse bloco
                  </Typography>
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                      <Table size={dense ? 'small' : 'medium'}>
                        <TableHeadCustom
                          headLabel={TABLE_HEAD}
                          rowCount={selectedTableData.length}
                          numSelected={selected.length}
                          onSort={() => {}}
                        />

                        <TableBody>
                          {selectedTableData
                            .filter((row) => getItemQuantity(row.id) > 0)
                            .map((row) => (
                              <ItemTableRowQuantitySelection
                                key={row.id}
                                row={row}
                                quantity={getItemQuantity(row.id)}
                                setQuantity={handleChangeItemQuantity}
                                isSelectedList
                              />
                            ))}

                          <TableNoData isNotFound={isNotFound} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Observação
                  </Typography>
                  <TextField
                    placeholder="Ex.: O SPDA deve ser projetado e instalado levando em consideração os sistemas elétricos da estrutura. Isso inclui a coordenação com dispositivos de proteção contra surtos para proteger os equipamentos elétricos internos."
                    fullWidth
                    multiline
                    minRows={4}
                    {...getFieldProps('observation')}
                    error={Boolean(touched.observation && errors.observation)}
                    helperText={touched.observation && errors.observation}
                  />
                </Grid>
              </Grid>
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!(isValid && dirty)}
              >
                {!isEdit ? 'Cadastrar' : 'Salvar'}
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
