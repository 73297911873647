import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';
import UserAccount from 'src/pages/dashboard/UserAccount';
import Metrics from 'src/pages/dashboard/metrics';
import Items from 'src/pages/dashboard/items';
import Tickets from 'src/pages/dashboard/tickets';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'account', element: <UserAccount /> },
        {
          path: 'management',
          children: [
            { element: <Navigate to="/dashboard/management/users" replace />, index: true },
            { path: 'users', element: <Users /> },
            { path: 'users/create', element: <UserCreate /> },
            { path: 'users/:id/edit', element: <UserCreate /> },

            { path: 'admins', element: <AdminUsers /> },
            { path: 'admins/create', element: <AdminUserCreate /> },
            { path: 'admins/:id/edit', element: <AdminUserCreate /> },

            { path: 'plans', element: <Plans /> },
            { path: 'plans/create', element: <PlanCreate /> },
            { path: 'plans/:id/edit', element: <PlanCreate /> },
          ],
        },

        { path: 'metrics', element: <Metrics /> },
        { path: 'tickets', element: <Tickets /> },
        { path: 'items', element: <Items /> },

        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// USER
const Users = Loadable(lazy(() => import('../pages/dashboard/management/users')));

const UserCreate = Loadable(lazy(() => import('../pages/dashboard/management/users/UserCreate')));

// ADMIN
const AdminUsers = Loadable(lazy(() => import('../pages/dashboard/management/admin')));
const AdminUserCreate = Loadable(
  lazy(() => import('../pages/dashboard/management/admin/AdminUserCreate'))
);

// PLANs
const Plans = Loadable(lazy(() => import('../pages/dashboard/management/plans')));
const PlanCreate = Loadable(lazy(() => import('../pages/dashboard/management/plans/PlanCreate')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
