import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { FormikProvider, useFormik, Form } from 'formik';
import { Stack, Card, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axiosInstance from 'src/utils/axios';

// ----------------------------------------------------------------------

type FormValuesProps = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export default function AccountChangePassword() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Digite a senha atual'),
    newPassword: Yup.string()
      .min(6, 'A senha deve ter pelo menos 6 caracteres')
      .required('Digite a nova senha'),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'As senhas devem ser iguais'
    ),
  });

  const formik = useFormik<FormValuesProps>({
    enableReinitialize: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: ChangePassWordSchema,
    onSubmit: async (data: FormValuesProps, { resetForm, setErrors }) => {
      try {
        await axiosInstance.post(`/users/verify-oldPassword/${user?.id}`, {
          oldPassword: data.oldPassword,
        });
        await axiosInstance.put(`/users/updatePassword/${user?.id}`, {
          newPassword: data.newPassword,
        });
        resetForm();
        enqueueSnackbar('Senha Atualizada com sucesso!');
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        console.error(error);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Card sx={{ p: 3 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} alignItems="center">
            <TextField
              fullWidth
              type="password"
              label="Senha atual"
              {...getFieldProps('oldPassword')}
              error={Boolean(touched.oldPassword && errors.oldPassword)}
              helperText={touched.oldPassword && errors.oldPassword}
            />

            <TextField
              fullWidth
              type="password"
              label="Nova senha"
              {...getFieldProps('newPassword')}
              error={Boolean(touched.newPassword && errors.newPassword)}
              helperText={touched.newPassword && errors.newPassword}
            />

            <TextField
              fullWidth
              type="password"
              label="Confirmar senha"
              {...getFieldProps('confirmNewPassword')}
              error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
              helperText={touched.confirmNewPassword && errors.confirmNewPassword}
            />

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Salvar
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}
