import { useState } from 'react';
import { TableRow, TableCell, Typography, IconButton, useTheme, Switch } from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import { ItemBundle } from 'src/@types/localEntity';
import axiosInstance from 'src/utils/axios';
import { apiRoutes } from 'src/routes/api';
import { useSnackbar } from 'notistack';
import { ModalConfirmations } from 'src/components/modal/ModalConfirmations';
import { ModalCreateItemBundle } from 'src/pages/dashboard/items/ModalCreateItemBundle';
import { ModalViewItemBundle } from 'src/pages/dashboard/items/ModalViewItemBundle';

// ----------------------------------------------------------------------

type Props = {
  row: ItemBundle;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  reQuery: () => void;
};

export default function ItemTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  reQuery,
}: Props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { id, description, observation, group } = row;

  const [createItemBundleModalOpen, setCreateItemBundleModalOpen] = useState(false);
  const openCreateItemBundleModal = () => setCreateItemBundleModalOpen(true);
  const closeCreateItemBundleModal = () => setCreateItemBundleModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);

  const [viewModalOpen, setViewModalOpen] = useState(false);
  const openViewModal = () => setViewModalOpen(true);
  const closeViewModal = () => setViewModalOpen(false);

  const handleDeactivate = async () => {
    await axiosInstance
      .patch(apiRoutes.itemBundles.deactivate(id))
      .then((data) => {
        enqueueSnackbar('Bloco desativado com sucesso!');
        reQuery();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
        reQuery();
      });
  };

  const handleActivate = async () => {
    await axiosInstance
      .patch(apiRoutes.itemBundles.activate(id))
      .then((data) => {
        enqueueSnackbar('Bloco ativado com sucesso!');
        reQuery();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
        reQuery();
      });
  };

  const handleDeleteRow = async () => {
    await axiosInstance
      .delete(apiRoutes.itemBundles.delete(id))
      .then((data) => {
        enqueueSnackbar('Bloco de Materiais removido com sucesso!');
        reQuery();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <>
      <TableRow hover selected={selected} key={id}>
        <TableCell sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography noWrap>{description}</Typography>
        </TableCell>

        <TableCell align="left">{observation}</TableCell>

        <TableCell align="left">{group?.name}</TableCell>

        <TableCell align="left">
          <IconButton onClick={openCreateItemBundleModal}>
            <Iconify
              color={theme.palette.primary.main}
              icon={'eva:edit-fill'}
              width={24}
              height={24}
            />
          </IconButton>
        </TableCell>

        <TableCell align="left">
          <IconButton onClick={openDeleteModal}>
            <Iconify
              color={theme.palette.primary.main}
              icon={'ph:trash-fill'}
              width={24}
              height={24}
            />
          </IconButton>
        </TableCell>

        <TableCell align="left">
          <IconButton onClick={openViewModal}>
            <Iconify
              color={theme.palette.primary.main}
              icon={'carbon:view-filled'}
              width={24}
              height={24}
            />
          </IconButton>
        </TableCell>

        <TableCell align="left">
          <Switch
            checked={row.active}
            onChange={() => {
              if (row.active) {
                handleDeactivate();
              } else {
                handleActivate();
              }
            }}
          />
        </TableCell>
      </TableRow>
      <ModalCreateItemBundle
        open={createItemBundleModalOpen}
        handleClose={closeCreateItemBundleModal}
        reQuery={reQuery}
        id={id}
        currentItemBundle={row}
      />
      <ModalConfirmations
        title={'Deseja excluir esse bloco de materiais?'}
        description={'Essa ação é definitiva e irreversível, deseja prosseguir?'}
        open={deleteModalOpen}
        handleClose={closeDeleteModal}
        execute={handleDeleteRow}
      />
      <ModalViewItemBundle open={viewModalOpen} handleClose={closeViewModal} id={id} />
    </>
  );
}
