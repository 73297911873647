import {
  Button,
  Chip,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';

import useSettings from 'src/hooks/useSettings';

import { PATH_DASHBOARD } from 'src/routes/paths';
import { ModalCreateTicket } from './ModalCreateTicket';
import { useEffect, useState } from 'react';
import axiosInstance from 'src/utils/axios';
import { ticket } from 'src/@types/localEntity';
import { useSnackbar } from 'notistack';

export default function Tickets() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const [open, setOpen] = useState(false);
  const [tickets, setTickets] = useState<ticket[] | []>([]);

  const handleClose = () => {
    setOpen(false);
  };

  const queryData = async () => {
    const tickets = await axiosInstance.get('payment/tickets');

    setTickets(tickets.data.data);
  };

  useEffect(() => {
    queryData();
  }, []);

  const deleteTicket = async (id: string) => {
    await axiosInstance
      .delete(`payment/ticket/${id}`)
      .then((data) => {
        enqueueSnackbar('Cupom de Desconto deletado com sucesso!');
        queryData();
      })
      .catch((error) => {
        console.error(error);

        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <Page title="Tickets">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Cupons"
          links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Cupons de desconto' }]}
          action={
            <Button
              variant="contained"
              onClick={() => setOpen(true)}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              fullWidth
            >
              Novo cupom de desconto
            </Button>
          }
        />
        <Divider />
        <ModalCreateTicket
          open={open}
          handleClose={handleClose}
          title="Criar novo cupom de desconto"
          execute={queryData}
        />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                {tickets.some((obj) => obj.name !== null) && (
                  <TableCell align="center">Descrição</TableCell>
                )}

                <TableCell align="center">Identificador</TableCell>
                <TableCell align="center" component="th" scope="row">
                  Ciclo de desconto
                </TableCell>
                <TableCell align="center">Desconto</TableCell>
                {/* <TableCell align="center">Quantidade</TableCell> */}
                <TableCell align="center">Resgatados/Total</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {tickets.map((ticket) => (
                <TableRow
                  key={ticket.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {tickets.some((obj) => obj.name !== null) && <TableCell>{ticket.name}</TableCell>}

                  <TableCell align="left">{ticket.id}</TableCell>
                  <TableCell align="left" sx={{ whiteSpace: 'nowrap', fontSize: '11px' }}>
                    {ticket.duration === 'once'
                      ? 'Apenas na 1ª parcela da assinatura!'
                      : ticket.duration === 'forever'
                      ? 'Todas as parcelas da assinatura'
                      : `Apenas nas ${ticket.duration_in_months} primeiras parcelas da assinatura`}
                  </TableCell>
                  <TableCell align="center">{`${ticket.percent_off}%`}</TableCell>
                  {/* <TableCell align="center">{ticket.max_redemptions}</TableCell> */}
                  <TableCell align="center">
                    {ticket.max_redemptions === null
                      ? `${ticket.times_redeemed} / Ilimitado`
                      : `${ticket.times_redeemed}/${ticket.max_redemptions}`}
                  </TableCell>
                  <TableCell align="center">
                    {ticket.valid ? (
                      <Chip sx={{ backgroundColor: theme.palette.success.light }} label="Ativo" />
                    ) : (
                      <Chip sx={{ backgroundColor: theme.palette.error.light }} label="Inativo" />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => {
                        deleteTicket(ticket.id);
                      }}
                    >
                      <Iconify color={theme.palette.error.main} icon={'eva:trash-2-outline'} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Page>
  );
}
