import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  TextField,
  Button,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CustomDatePicker from 'src/components/DatePicker';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import { CalcsByDimensioningCountChart } from 'src/components/chart/metrics/calcsByDimensioningCountChart';
import { LoginDateChart } from 'src/components/chart/metrics/loginDateChart';
import { ProjectCreationDateChart } from 'src/components/chart/metrics/projectCreationDateChart';
import { SubscriptionByPlanCountChart } from 'src/components/chart/metrics/subscriptionByPlanCountChart';
import { SubscriptionCreationDateChart } from 'src/components/chart/metrics/subscriptionCreationDateChart';
import useSettings from 'src/hooks/useSettings';
import { apiRoutes } from 'src/routes/api';
import { PATH_DASHBOARD } from 'src/routes/paths';
import palette from 'src/theme/palette';
import axios from 'src/utils/axios';

export default function Metrics() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { themeStretch } = useSettings();

  const [loginsData, setLoginsData] = useState([]);
  const [subscriptionsCreationData, setSubscriptionsCreationData] = useState([]);
  const [projectsCreationData, setProjectsCreationData] = useState([]);

  const [calcsByDimensioningTypeCount, setCalcsByDimensioningTypeCount] = useState([]);
  const [subscriptionByPlanCount, setSubscriptionByPlanCount] = useState([]);

  const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const queryData = async () => {
    const postData = {
      startDate: startDateFilter,
      endDate: endDateFilter,
    };

    const { data: loginsData } = await axios.post(apiRoutes.metrics.logins, postData);
    setLoginsData(loginsData);

    const { data: subscriptionsCreationDate } = await axios.post(
      apiRoutes.metrics.subscriptionsCreation,
      postData
    );
    setSubscriptionsCreationData(subscriptionsCreationDate);

    const { data: projectsCreationDate } = await axios.post(
      apiRoutes.metrics.projectsCreation,
      postData
    );
    setProjectsCreationData(projectsCreationDate);

    const { data: planCount } = await axios.post(apiRoutes.metrics.planCount, postData);
    setSubscriptionByPlanCount(planCount);

    const { data: dimensioningCount } = await axios.post(
      apiRoutes.metrics.dimensioningCount,
      postData
    );
    setCalcsByDimensioningTypeCount(dimensioningCount);
  };

  useEffect(() => {
    queryData();
  }, [startDateFilter, endDateFilter]);

  return (
    <Page title="Métricas">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Indicadores"
          links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Indicadores' }]}
        />
        <Divider />
        <Grid
          container
          justifyContent={'space-between'}
          alignItems={'center'}
          alignContent={'center'}
          rowGap={7}
          marginTop={3}
        >
          <Grid xs={12}>
            <CustomDatePicker
              filterDate={startDateFilter}
              setFilterDate={setStartDateFilter}
              label="De: "
            />
            <CustomDatePicker
              filterDate={endDateFilter}
              setFilterDate={setEndDateFilter}
              label="Até: "
            />
            <Button
              size="large"
              variant="outlined"
              color="error"
              onClick={() => {
                setStartDateFilter(null);
                setEndDateFilter(null);
              }}
            >
              <Typography color={theme.palette.error.main} paddingRight={1}>
                Limpar Filtros
              </Typography>
              <Iconify
                icon={'tabler:filter-x'}
                color={theme.palette.error.main}
                width={24}
                height={24}
              />
            </Button>
          </Grid>

          <Grid xs={12} md={5.7} sx={{ boxShadow: 4 }} borderRadius={3} padding={2}>
            <Typography
              variant="body1"
              sx={{
                color: palette.light.grey[800],
                fontWeight: '600',
                mb: '10px',
                textAlign: 'center',
              }}
            >
              Acessos a aplicação
            </Typography>
            <LoginDateChart data={loginsData} />
          </Grid>

          <Grid xs={12} md={5.7} sx={{ boxShadow: 4 }} borderRadius={3} padding={2}>
            <Typography
              variant="body1"
              sx={{
                color: palette.light.grey[800],
                fontWeight: '600',
                mb: '10px',
                textAlign: 'center',
              }}
            >
              Projetos criados
            </Typography>
            <ProjectCreationDateChart data={projectsCreationData} />
          </Grid>

          <Grid xs={12} md={12} sx={{ boxShadow: 4 }} borderRadius={3} padding={2}>
            <Typography
              variant="body1"
              sx={{
                color: palette.light.grey[800],
                fontWeight: '600',
                mb: '10px',
                textAlign: 'center',
              }}
            >
              Assinaturas realizadas
            </Typography>
            <SubscriptionCreationDateChart data={subscriptionsCreationData} />
          </Grid>

          <Grid xs={12} md={5.7} sx={{ boxShadow: 4 }} borderRadius={3} padding={2}>
            <Typography
              variant="body1"
              sx={{
                color: palette.light.grey[800],
                fontWeight: '600',
                mb: '10px',
                textAlign: 'center',
              }}
            >
              Assinaturas por plano
            </Typography>
            <SubscriptionByPlanCountChart data={subscriptionByPlanCount} />
          </Grid>

          <Grid xs={12} md={5.7} sx={{ boxShadow: 4 }} borderRadius={3} padding={2}>
            <Typography
              variant="body1"
              sx={{
                color: palette.light.grey[800],
                fontWeight: '600',
                mb: '10px',
              }}
            >
              Cálculos por tipo de dimensionamento
            </Typography>
            <CalcsByDimensioningCountChart data={calcsByDimensioningTypeCount} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
