import { PATH_DASHBOARD } from '../../../routes/paths';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    subheader: 'Gerenciamento',
    items: [
      {
        title: 'Clientes',
        path: PATH_DASHBOARD.management.users,
        icon: <Iconify icon="teenyicons:users-solid" />,
      },
      {
        title: 'Colaboradores',
        path: PATH_DASHBOARD.management.admins,
        icon: <Iconify icon="eos-icons:admin" />,
      },
      {
        title: 'Planos',
        path: PATH_DASHBOARD.management.plans,
        icon: <Iconify icon="bi:stack" />,
      },
      {
        title: 'Materiais',
        path: PATH_DASHBOARD.items,
        icon: <Iconify icon="solar:document-text-linear" />,
      },
      {
        title: 'Indicadores',
        path: PATH_DASHBOARD.metrics,
        icon: <Iconify icon="nimbus:stats" />,
      },
      {
        title: 'Cupons',
        path: PATH_DASHBOARD.tickets,
        icon: <Iconify icon="heroicons:ticket" />,
      },
    ],
  },
];

export default navConfig;
