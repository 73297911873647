import { Box, IconButton, TextField, Typography } from '@mui/material';
import Iconify from '../Iconify';

interface propsInterface {
  id: string;
  quantity: number;
  setQuantity: (id: string, newQuantity: number) => void;
}

export default function QuantitySelector({ quantity, setQuantity, id }: propsInterface) {
  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(id, Number(event.target.value));
  };
  
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <Iconify
            icon={'ic:round-minus'}
            width={24}
            height={24}
            onClick={() => {
              setQuantity(id, quantity - 1);
            }}
          />
        </IconButton>

        <TextField
          type="number"
          value={quantity}
          onChange={handleChangeQuantity}
          sx={{ width: `3.4rem` }}
          inputProps={{ style: { textAlign: 'center' } }}
        />

        <IconButton>
          <Iconify
            icon={'ic:round-plus'}
            width={24}
            height={24}
            onClick={() => {
              setQuantity(id, quantity + 1);
            }}
          />
        </IconButton>
      </Box>
    </>
  );
}
