import { DialogContent, TableRow, TableCell, Dialog, Button } from '@mui/material';
import { useState } from 'react';
// components
import { Item } from 'src/@types/localEntity';
import Iconify from 'src/components/Iconify';
import Image from 'src/components/Image';
import QuantitySelector from 'src/components/quantity-selector/quantitySelector';
import palette from 'src/theme/palette';

// ----------------------------------------------------------------------

type Props = {
  row: Item;
  quantity: number;
  setQuantity: (id: string, newQuantity: number) => void;
  isView?: boolean;
  isSelectedList?: boolean;
};

export default function ItemTableRowQuantitySelection({
  row,
  quantity,
  setQuantity,
  isView = false,
  isSelectedList = false,
}: Props) {
  const { id, image_url, description, specification, complement, reference, unit, group } = row;
  const [openModalImage, setOpenModalImage] = useState(false);
  const [imageOpen, setImageOpen] = useState('');

  const openImage = (image: string | undefined) => {
    if (image) {
      setImageOpen(image);
      setOpenModalImage(true);
    }
  };

  return (
    <>
      <TableRow hover key={id}>
        <TableCell
          style={{
            cursor: 'pointer',
          }}
          onClick={() => openImage(image_url)}
        >
          {image_url ? (
            <Image
              src={image_url}
              style={{ width: '70px !important', height: '70px !important' }}
            />
          ) : (
            <div
              style={{
                height: '70px',
                width: '70px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Iconify icon={'carbon:no-image'} sx={{ fontSize: 25 }} />
              <p style={{ fontSize: '11px' }}>sem imagem</p>
            </div>
          )}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            color:
              !isView && !isSelectedList && quantity > 0
                ? palette.light.text.disabled
                : palette.light.text.primary,
          }}
        >
          {description}
        </TableCell>

        <TableCell
          align="left"
          sx={{
            color:
              !isView && !isSelectedList && quantity > 0
                ? palette.light.text.disabled
                : palette.light.text.primary,
          }}
        >
          {specification}
        </TableCell>

        <TableCell
          align="left"
          sx={{
            color:
              !isView && !isSelectedList && quantity > 0
                ? palette.light.text.disabled
                : palette.light.text.primary,
          }}
        >
          {complement}
        </TableCell>

        <TableCell
          align="left"
          sx={{
            color:
              !isView && !isSelectedList && quantity > 0
                ? palette.light.text.disabled
                : palette.light.text.primary,
          }}
        >
          {reference}
        </TableCell>

        <TableCell
          align="left"
          sx={{
            color:
              !isView && !isSelectedList && quantity > 0
                ? palette.light.text.disabled
                : palette.light.text.primary,
          }}
        >
          {group?.name}
        </TableCell>

        <TableCell
          align="left"
          sx={{
            color:
              !isView && !isSelectedList && quantity > 0
                ? palette.light.text.disabled
                : palette.light.text.primary,
          }}
        >
          {unit}
        </TableCell>

        <TableCell align="left" width={'6rem'}>
          {isView ? (
            quantity
          ) : isSelectedList ? (
            <QuantitySelector id={row.id} quantity={quantity} setQuantity={setQuantity} />
          ) : (
            <Button
              disabled={quantity != 0}
              onClick={() => {
                setQuantity(row.id, 1);
              }}
            >
              +
            </Button>
          )}
        </TableCell>
      </TableRow>
      <ModalImage
        open={openModalImage}
        image={imageOpen}
        handleClose={() => setOpenModalImage(false)}
      />
    </>
  );
}

interface ModalImageProps {
  open: boolean;
  handleClose: () => void;
  image: string;
}

function ModalImage({ open, handleClose, image }: ModalImageProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Image src={image} style={{ width: '80vw' }} />
      </DialogContent>
    </Dialog>
  );
}
