import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DatePicker from '@mui/lab/DatePicker';
import DateFnsAdapter from '@date-io/date-fns'; // Importe o DateFnsAdapter do @date-io/date-fns

import { LocalizationProvider as MuiLocalizationProvider } from '@mui/lab';
import ptLocale from 'date-fns/locale/pt-BR'; // Importe o locale para o português do Brasil

interface CustomDatePickerProps {
  filterDate: Date | null;
  setFilterDate: (date: Date | null) => void;
  label: string;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  filterDate,
  setFilterDate,
  label,
}) => {
  return (
    <>
      <MuiLocalizationProvider dateAdapter={DateFnsAdapter} locale={ptLocale}>
        <DatePicker
          value={filterDate}
          onChange={(newValue) => setFilterDate(newValue)}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label={label}
                variant="outlined"
                size="medium"
                style={{ cursor: 'pointer', paddingRight: '36px' }}
              />
              <IconButton
                onClick={() => params.inputProps?.ref?.current?.click()}
                size="medium"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              ></IconButton>
            </>
          )}
        />
      </MuiLocalizationProvider>
    </>
  );
};

export default CustomDatePicker;
