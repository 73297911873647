import React, { Component, ErrorInfo, ReactNode } from 'react';
import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from '../../components/Page';
import { MotionContainer, varBounce } from '../../components/animate';
// assets
import { PageNotFoundIllustration } from '../../assets';
import palette from 'src/theme/palette';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: '90vh',
  maxHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorMessage: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: '',
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMessage: _.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public resetErrorState = () => {
    this.setState({ hasError: false, errorMessage: '' });
  };

  public render() {
    if (this.state.hasError) {
      return (
        <Page title="Error">
          <Container component={MotionContainer}>
            <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
              <m.div variants={varBounce().in}>
                <Typography variant="h3" paragraph>
                  Ops...
                </Typography>
              </m.div>

              <m.div variants={varBounce().in}>
                <Typography sx={{ color: 'text.secondary' }}>
                  Ocorreu um erro inesperado.
                </Typography>
              </m.div>

              <m.div variants={varBounce().in}>
                <Typography sx={{ color: palette.light.error.main, paddingBottom: '10px' }}>
                  {this.state.errorMessage}
                </Typography>
              </m.div>

              <Button
                to="/"
                size="large"
                variant="contained"
                component={RouterLink}
                onClick={this.resetErrorState}
              >
                Voltar
              </Button>
            </ContentStyle>
          </Container>
        </Page>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
