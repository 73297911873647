import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Card,
  Box,
  TextField,
  Stack,
  useTheme,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import axiosInstance from 'src/utils/axios';
import { FormikProvider, useFormik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/Iconify';
import { NewTicketSchema } from './validations';

interface FormValuesProps {
  description?: string | null;
  duration: string;
  duration_in_months?: number | null;
  id?: string | null;
  percent_off: number | null;
  quantity?: number | null;
  redeem_by: Date | null;
}

interface ModalCreateTicketProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  execute?: () => void;
}
export function ModalCreateTicket({ title, open, handleClose, execute }: ModalCreateTicketProps) {
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const formik = useFormik<FormValuesProps>({
    enableReinitialize: true,
    initialValues: {
      description: null,
      duration: '',
      duration_in_months: null,
      id: null,
      percent_off: null,
      quantity: null,
      redeem_by: null,
    },
    validationSchema: NewTicketSchema,
    onSubmit: async (values: FormValuesProps, { resetForm, setErrors }) => {
      const data = {
        name: values.description,
        percent_off: values.percent_off,
        duration: values.duration,
        duration_in_months: values.duration_in_months,
        max_redemptions: values.quantity,
        redeem_by: values.redeem_by,
        id: values.id,
      };
      await axiosInstance
        .post('payment/ticket', data)
        .then((data) => {
          enqueueSnackbar('Cupom de Desconto criado com sucesso!');
        })
        .catch((error) => {
          console.error(error);

          enqueueSnackbar(error.message, { variant: 'error' });
        });
      execute && execute();
      handleClose();
      resetForm();
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
    isValid,
    dirty,
    values,
  } = formik;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        {title}
        <div
          style={{
            borderRadius: '3px',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.grey[300],
            boxShadow: '0px 1px 2px rgba(145, 158, 171, 0.24)',
            cursor: 'pointer',
          }}
          onClick={() => handleClose()}
        >
          <Iconify icon="material-symbols:close" fontSize="10px" color={theme.palette.grey[600]} />
        </div>
      </DialogTitle>
      <DialogContent style={{ marginTop: '20px' }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box>
              <Grid container spacing={2} sx={{ marginBottom: '15px' }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Descrição
                  </Typography>
                  <TextField
                    fullWidth
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Percentual de desconto
                    <span style={{ color: `${theme.palette.error.main}` }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    type="number"
                    placeholder="%"
                    {...getFieldProps('percent_off')}
                    error={Boolean(touched.percent_off && errors.percent_off)}
                    helperText={touched.percent_off && errors.percent_off}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: '15px' }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Ciclo de Desconto
                    <span style={{ color: `${theme.palette.error.main}` }}>*</span>
                  </Typography>

                  <Select fullWidth id="duration" {...getFieldProps('duration')}>
                    <MenuItem value={'once'}>Apenas na primeira parcela da assinatura</MenuItem>
                    <MenuItem value={'forever'}>Todas as parcelas da assinatura</MenuItem>
                    <MenuItem value={'repeating'}>
                      Apenas nos primeiros x meses da assinaturas
                    </MenuItem>
                  </Select>
                  {touched.duration && errors.duration && (
                    <FormHelperText>{errors.duration}</FormHelperText>
                  )}
                </Grid>
                {values.duration === 'repeating' && (
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      Duração em Meses
                      <span style={{ color: `${theme.palette.error.main}` }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      type="number"
                      placeholder="12"
                      {...getFieldProps('duration_in_months')}
                      error={Boolean(touched.duration_in_months && errors.duration_in_months)}
                      helperText={touched.duration_in_months && errors.duration_in_months}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2} sx={{ marginBottom: '15px' }}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Quantidade
                  </Typography>

                  <TextField
                    fullWidth
                    type="number"
                    {...getFieldProps('quantity')}
                    error={Boolean(touched.quantity && errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Vencimento
                  </Typography>

                  <TextField
                    type="datetime-local"
                    {...getFieldProps('redeem_by')}
                    error={Boolean(touched.redeem_by && errors.redeem_by)}
                    helperText={touched.redeem_by && errors.redeem_by}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Card
                  style={{
                    backgroundColor: `${theme.palette.grey[200]}`,
                    padding: '0',
                    borderRadius: '0',
                  }}
                >
                  <Typography variant="subtitle2" color={theme.palette.primary.main}>
                    Identificador
                  </Typography>
                  <TextField
                    fullWidth
                    {...getFieldProps('id')}
                    error={Boolean(touched.id && errors.id)}
                    helperText={touched.id && errors.id}
                  />
                </Card>
              </Grid>
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!(isValid && dirty)}
              >
                Criar novo Cupom
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
