import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  TextField,
  Stack,
  useTheme,
  Typography,
  Select,
  FormHelperText,
  MenuItem,
  Card,
  ButtonBase,
  Button,
  DialogActions
} from '@mui/material';
import axiosInstance from 'src/utils/axios';
import { FormikProvider, useFormik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { SetStateAction, useEffect, useState } from 'react';
import { Item, ItemGroup } from 'src/@types/localEntity';
import Iconify from 'src/components/Iconify';
import { apiRoutes } from 'src/routes/api/index';
import { EditPlanSchema, NewPlanSchema } from 'src/sections/@dashboard/items/validations';
import Image from 'src/components/Image';

interface FormValuesProps {
  description: string;
  specification: string;
  complement: string;
  reference: string;
  unit: string;
  group_id?: string;
  image?: string;
}

interface ModalCreateItemProps {
  open: boolean;
  handleClose: () => void;
  reQuery: () => void;
  currentItem?: Item;
  id?: string;
}
interface ExistingImage {
  id: string;
  image_url: string;
}
export function ModalCreateItem({
  //description,
  open,
  handleClose,
  reQuery,
  currentItem,
  id,
}: ModalCreateItemProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [groups, setGroups] = useState<ItemGroup[]>([]);
  const [existingImages, setExistingImages] = useState<ExistingImage[]>([]);
  const [openSelectImageDialog, setOpenSelectImageDialog] = useState(false);
  const [openOverwriteDialog, setOpenOverwriteDialog] = useState(false);

  let [arq, setArq] = useState<any>(null);
  const [urlFile, setUrlFile] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [filter, setFilter] = useState('');

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const arquivoSelecionado = event.target.files?.[0];

    if (arquivoSelecionado) {
      setArq(arquivoSelecionado);

      const url = URL.createObjectURL(arquivoSelecionado);
      setUrlFile(url);
    }
  };

  const queryGroups = async () => {
    await axiosInstance
      .get(apiRoutes.items.readGroups())
      .then((data) => {
        setGroups(data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const queryExistingImages = async (filter: { urlContains?: string } = {}) => {
    try {
      const url = apiRoutes.items.readExistingImages();

      const response = await axiosInstance.get(url, { params: filter });

      if (Array.isArray(response.data)) {
        setExistingImages(response.data);
      } else {
        console.error('Resposta inesperada da API, esperado um array', response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar imagens existentes', error);
    }
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setFilter(inputValue);
  };

  useEffect(() => {
    queryExistingImages({ urlContains: filter });
  }, [filter]);

  useEffect(() => {
    queryGroups();
  }, []);

  const isEdit = currentItem && id;

  const theme = useTheme();
  const formik = useFormik<FormValuesProps>({
    enableReinitialize: true,
    initialValues: {
      description: currentItem?.description || '',
      specification: currentItem?.specification || '',
      complement: currentItem?.complement || '',
      reference: currentItem?.reference || '',
      group_id: currentItem?.group_id || '',
      unit: currentItem?.unit || ''
    },
    validationSchema: !isEdit ? NewPlanSchema : EditPlanSchema,
    onSubmit: async (values: FormValuesProps, { resetForm, setErrors }) => {
      const data = {
        description: values.description,
        specification: values.specification,
        complement: values.complement,
        reference: values.reference,
        group_id: values.group_id,
        unit: values.unit
      };
      if (!isEdit) {
        try {
          const createResponse = await axiosInstance.post(apiRoutes.items.create(), data);

          if (arq) {
            const formData = new FormData();
            formData.append('file', arq);
            await axiosInstance.post(apiRoutes.items.uploadImage(createResponse.data.id), formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            enqueueSnackbar('Material criado com sucesso!');
          } else if (selectedImage) {
            await axiosInstance.post(apiRoutes.items.selectedImage(createResponse.data.id), {
              imageUrl: selectedImage,
            });
            enqueueSnackbar('Material criado com sucesso!');
          }

          reQuery();
          handleClose();
          resetForm();
        } catch (error) {
          if (error) {
            setOpenOverwriteDialog(true);
            console.log(error);
          }
        }
      } else if (isEdit && id) {
        await axiosInstance.patch(apiRoutes.items.update(id), data)
          .then(async () => {
            if (arq) {
              const formData = new FormData();
              formData.append('file', arq);

              await axiosInstance.post(apiRoutes.items.uploadImage(id), formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            } else if (selectedImage) {
              await axiosInstance.post(apiRoutes.items.selectedImage(id), {
                imageUrl: selectedImage,
              });
            }

            enqueueSnackbar('Material atualizado com sucesso!');
            reQuery();

            handleClose();
            resetForm();
          })
          .catch((error) => {
            if (error) {
              setOpenOverwriteDialog(true);
              console.log(error);
            }
          });
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
    isValid,
    dirty,
  } = formik;

  useEffect(() => {
    if (isEdit && currentItem) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentItem]);
  const isAnyFieldFilled = (values: FormValuesProps) => (
    values.description.trim() !== '' ||
    values.specification.trim() !== '' ||
    values.complement.trim() !== '' ||
    values.reference.trim() !== '' ||
    values.unit.trim() !== '' ||
    values.group_id !== ''
  );
  const handleSelectImage = (image: string) => {
    setSelectedImage(image);
    setOpenSelectImageDialog(false);
  };
  const handleOverwriteConfirm = async () => {
    setOpenOverwriteDialog(false);
    const createResponse = await axiosInstance.post(apiRoutes.items.create(), {
      description: formik.values.description,
      specification: formik.values.specification,
      complement: formik.values.complement,
      reference: formik.values.reference,
      group_id: formik.values.group_id,
      unit: formik.values.unit
    });

    try {
      if (arq && !isEdit) {
        const formData = new FormData();
        formData.append('file', arq);
        await axiosInstance.patch(apiRoutes.items.updateImageUrl(createResponse.data.id), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        enqueueSnackbar('Material criado e atualizado com a imagem existente!');
        reQuery();
        handleClose();
        resetForm();
      } else if (isEdit) {
        const data = {
          description: formik.values.description,
          specification: formik.values.specification,
          complement: formik.values.complement,
          reference: formik.values.reference,
          group_id: formik.values.group_id,
          unit: formik.values.unit,
        };

        await axiosInstance.patch(apiRoutes.items.update(id), data);

        if (arq) {
          const formData = new FormData();
          formData.append('file', arq);
          await axiosInstance.patch(apiRoutes.items.updateImageUrl(id), formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          enqueueSnackbar('Material atualizado e imagem sobrescrita com sucesso!');
          reQuery();
          window.location.reload();
        }
      }
      reQuery();
      handleClose();
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          setArq(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          {currentItem ? `Editar Material` : `Cadastrar novo Material`}
          <div
            style={{
              borderRadius: '3px',
              width: '20px',
              height: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: theme.palette.grey[300],
              boxShadow: '0px 1px 2px rgba(145, 158, 171, 0.24)',
              cursor: 'pointer',
            }}
            onClick={() => handleClose()}
          >
            <Iconify icon="material-symbols:close" fontSize="10px" color={theme.palette.grey[600]} />
          </div>
        </DialogTitle>
        <DialogContent style={{ marginTop: '20px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Box>
                <Grid container spacing={2} sx={{ marginBottom: '15px' }}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      Descrição
                    </Typography>
                    <TextField
                      placeholder="Ex.: Mastro simples em aço galvanizado"
                      fullWidth
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      Especificação
                    </Typography>
                    <TextField
                      placeholder="Ex.: altura h=5 m (5m x Φ2)"
                      fullWidth
                      {...getFieldProps('specification')}
                      error={Boolean(touched.specification && errors.specification)}
                      helperText={touched.specification && errors.specification}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      Complemento
                    </Typography>
                    <TextField
                      placeholder="Ex.: Embalagem em bisnaga de 380 gr..."
                      fullWidth
                      {...getFieldProps('complement')}
                      error={Boolean(touched.complement && errors.complement)}
                      helperText={touched.complement && errors.complement}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      Referência
                    </Typography>
                    <TextField
                      placeholder="Ex.: Termotécnica ou Similar"
                      fullWidth
                      {...getFieldProps('reference')}
                      error={Boolean(touched.reference && errors.reference)}
                      helperText={touched.reference && errors.reference}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      Grupo de materiais
                    </Typography>
                    <Select
                      fullWidth
                      {...getFieldProps('group_id')}
                      error={Boolean(touched.group_id && errors.group_id)}
                    >
                      {groups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.group_id && errors.group_id && (
                      <FormHelperText>{errors.group_id}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      Unidade
                    </Typography>
                    <TextField
                      placeholder="Ex.: Bisnaga"
                      fullWidth
                      {...getFieldProps('unit')}
                      error={Boolean(touched.unit && errors.unit)}
                      helperText={touched.unit && errors.unit}
                    />
                  </Grid>
                  <Stack spacing={2} justifyContent="space-between">
                    <Box>
                      {urlFile ? (
                        <Box
                          sx={{
                            position: 'relative',
                            borderRadius: 1,
                            overflow: 'hidden',
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          <Image alt="file preview" src={urlFile} ratio="1/1" />
                        </Box>
                      ) : (
                        selectedImage && (
                          <Box
                            sx={{
                              position: 'relative',
                              borderRadius: 1,
                              overflow: 'hidden',
                              backgroundColor: theme.palette.primary.main,
                            }}
                          >
                            <Image alt="selected image" src={selectedImage} ratio="1/1" />
                          </Box>
                        )
                      )}
                    </Box>
                    <Stack direction="column" spacing={1}>
                      <ButtonBase
                        sx={{
                          width: '100%',
                          height: 'auto',
                          padding: '8px 16px',
                          borderRadius: 1,
                          backgroundColor: theme.palette.primary.main,
                          textAlign: 'center',
                          border: `1px dashed ${theme.palette.divider}`,
                        }}
                        component="label"
                      >
                        Adicionar Imagem
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={handleFileChange}
                        />
                      </ButtonBase>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenSelectImageDialog(true)}
                      >
                        Selecionar Imagem Existente
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          onChange={handleFileChange}
                        />
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </Box>
              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid || !isAnyFieldFilled(formik.values)}
                >
                  {!isEdit ? 'Cadastrar' : 'Salvar'}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSelectImageDialog}
        onClose={() => setOpenSelectImageDialog(false)}
        aria-labelledby="select-image-dialog-title"
      >
        <DialogTitle id="select-image-dialog-title">Selecionar Imagem Existente</DialogTitle>
        <DialogContent>
          <TextField
            label="Filtrar por nome"
            variant="outlined"
            fullWidth
            value={filter}
            onChange={handleFilterChange}
            margin="normal"
          />
          <Grid container spacing={2} marginTop={2}>
            {existingImages.length > 0 ? (
              existingImages.map((image) => (
                <Grid item xs={4} key={image.id}>
                  <Card
                    sx={{
                      cursor: 'pointer',
                      border: selectedImage === image.id ? `2px solid blue` : 'none',
                    }}
                    onClick={() => handleSelectImage(image.image_url)}
                  >
                    <Image src={image.image_url} alt={`Imagem ${image.id}`} ratio="1/1" />
                  </Card>
                </Grid>
              ))
            ) : (
              <Typography>Não há imagens existentes disponíveis.</Typography>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSelectImageDialog(false)} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openOverwriteDialog}
        onClose={() => setOpenOverwriteDialog(false)}
        aria-labelledby="confirm-dialog-title"
      >
        <DialogTitle id="confirm-dialog-title">Confirmar Sobrescrição</DialogTitle>
        <DialogContent>
          <Typography>
            Já existe uma imagem com este nome de arquivo. Deseja sobrescrever a imagem?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenOverwriteDialog(false)}>Cancelar</Button>
          <Button
            onClick={handleOverwriteConfirm}
            color="primary"
          >
            Sobrescrever
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
