import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { ItemBundle } from 'src/@types/localEntity';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from 'src/components/table';
import useTable from 'src/hooks/useTable';
import { apiRoutes } from 'src/routes/api';
import {
  ItemBundleTableRow,
  ItemBundleTableToolbar,
} from 'src/sections/@dashboard/itemBundle/list';
import axiosInstance from 'src/utils/axios';
import { ModalCreateItemBundle } from './ModalCreateItemBundle';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'description', label: 'Descrição', align: 'left' },
  { id: 'observation', label: 'Observação', align: 'left' },
  { id: 'group', label: 'Grupo de materiais', align: 'left' },
  { id: '' },
  { id: '' },
  { id: '' },
  { id: '' },
];

// ----------------------------------------------------------------------

interface propsInterface {}

export default function ItemBundleTab({}: propsInterface) {
  // createModal

  const [createItemBundleModalOpen, setCreateItemBundleModalOpen] = useState(false);
  const openCreateItemBundleModal = () => setCreateItemBundleModalOpen(true);
  const closeCreateItemBundleModal = () => setCreateItemBundleModalOpen(false);

  const {
    page,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const [tableData, setTableData] = useState<ItemBundle[]>([]);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [filterName, setFilterName] = useState('');

  const queryData = async () => {
    const itemBundles = await axiosInstance.get(
      apiRoutes.itemBundles.read(page, rowsPerPage, filterName)
    );

    console.log(itemBundles);

    if (itemBundles) {
      setTableData(itemBundles?.data.bundles);
      setTableDataLength(itemBundles?.data.count);
    }
  };

  useEffect(() => {
    queryData();
  }, [filterName, page]);

  // table functions
  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const isNotFound = !tableData.length && !!filterName;
  return (
    <>
      <Grid container spacing={3} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item xs={12} md={6}>
          <ItemBundleTableToolbar
            filterName={filterName}
            onFilterName={handleFilterName}
            searchLabel="Buscar por um bloco de materiais..."
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            onClick={openCreateItemBundleModal}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            fullWidth
            size="large"
          >
            Novo Bloco de Materiais
          </Button>
        </Grid>
      </Grid>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          {selected.length > 0 && (
            <TableSelectedActions
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row?.id || '')
                )
              }
            />
          )}

          <Table size={'small'}>
            <TableHeadCustom
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              numSelected={selected.length}
              onSort={onSort}
            />

            <TableBody>
              {tableData.map((row) => (
                <ItemBundleTableRow
                  key={row.id}
                  row={row}
                  selected={selected.includes(row?.id || '')}
                  onSelectRow={() => null}
                  onDeleteRow={() => null}
                  onEditRow={() => null}
                  reQuery={queryData}
                />
              ))}

              <TableEmptyRows emptyRows={rowsPerPage - tableData.length} />

              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tableDataLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
        />
      </Box>
      <ModalCreateItemBundle
        open={createItemBundleModalOpen}
        handleClose={closeCreateItemBundleModal}
        reQuery={queryData}
      />
    </>
  );
}

function applySortFilter({
  tableData,
  comparator,
  filterName,
}: {
  tableData: ItemBundle[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.description.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}
