import ReactApexChart, { Props as reactApexChartsProps } from 'react-apexcharts';

interface Props {
  data: {
    id: string;
    name: string;
    _count: {
      subscription: number;
    };
  }[];
}

export const SubscriptionByPlanCountChart = ({ data }: Props) => {
  const plans = data.map((plan) => plan.name);
  const count = data.map((plan) => plan._count.subscription);

  const chartData: reactApexChartsProps = {
    options: {
      legend: {
        position: 'bottom',
      },
      labels: plans, // Rótulos para cada setor
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    series: count,
  };

  return <ReactApexChart options={chartData.options} series={chartData.series} type="donut" />;
};
