import {
  Grid,
  Box,
  TableContainer,
  TableBody,
  Table,
  Select,
  MenuItem,
  Button,
  IconButton
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { Item } from 'src/@types/localEntity';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import {
  TableEmptyRows,
  TableNoData,
  TableSelectedActions,
} from 'src/components/table';
import useTable from 'src/hooks/useTable';
import { apiRoutes } from 'src/routes/api';
import { ItemTableRow, ItemTableToolbar } from 'src/sections/@dashboard/items/list';
import axiosInstance from 'src/utils/axios';
import { ModalCreateItem } from './ModalCreateItem';

// ----------------------------------------------------------------------

interface propsInterface { }

export default function ItemTab({ }: propsInterface) {
  // createModal

  const [createMaterialModalOpen, setCreateMaterialModalOpen] = useState(false);
  const openCreateMaterialModal = () => setCreateMaterialModalOpen(true);
  const closeCreateMaterialModal = () => setCreateMaterialModalOpen(false);

  const {
    page,
    //rowsPerPage,
    setPage,
    //
    selected,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const [tableData, setTableData] = useState<Item[]>([]);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [filterName, setFilterName] = useState('');
  const rowsPerPage = 5;

  const queryData = async () => {
    const response = await axiosInstance.get(apiRoutes.items.read(page, rowsPerPage, filterName));

    if (response) {
      const processedData = response.data.paginatedItems.flatMap((group: any) => group);
      setTableData(processedData);
      setTableDataLength(response.data.totalPages);
    }
  };

  useEffect(() => {
    queryData();
  }, [filterName, page, rowsPerPage]);
  const groupedItems = useMemo(() => {
    const grouped: Record<string, Item[]> = tableData.reduce((acc: Record<string, Item[]>, item: Item) => {
      const { image_url } = item;
      if (image_url) {
        if (!acc[image_url]) {
          acc[image_url] = [];
        }
        acc[image_url].push(item);
      }
      return acc;
    }, {});
    return grouped;
  }, [tableData]);
  const uniqueItems = useMemo(() => Object.values(groupedItems).map(items => items[0]), [groupedItems]);

  // table functions
  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const isNotFound = !tableData?.length && !!filterName;
  const totalPages = tableDataLength;

  return (
    <>
      <Grid container spacing={3} justifyContent={'space-between'} alignItems={'center'}>
        <Grid item xs={12} md={6}>
          <ItemTableToolbar
            filterName={filterName}
            onFilterName={handleFilterName}
            searchLabel="Buscar por um material..."
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            onClick={openCreateMaterialModal}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            fullWidth
            size="large"
          >
            Novo material
          </Button>
        </Grid>
      </Grid>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1300, position: 'relative' }}>
          {selected?.length > 0 && (
            <TableSelectedActions
              numSelected={selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row?.id || '')
                )
              }
            />
          )}

          <Table size={'small'}>
            <TableBody>
              {uniqueItems.map((row) => (
                <ItemTableRow
                  key={row.id}
                  row={row}
                  selected={selected.includes(row.id)}
                  onSelectRow={() => null}
                  onDeleteRow={() => null}
                  onEditRow={() => null}
                  reQuery={queryData}
                  existingUrls={tableData.filter(item => item.image_url === row.image_url)}
                />
              ))}

              <TableEmptyRows emptyRows={rowsPerPage - tableData?.length} />

              <TableNoData isNotFound={isNotFound} />
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <IconButton
          onClick={() => onChangePage({}, page - 1)}
          disabled={page === 0}
          sx={{ marginRight: 1 }}
        >
          <Iconify icon="eva:arrow-back-fill" />
        </IconButton>

        <Select
          value={page}
          onChange={(e) => setPage(Number(e.target.value))}
          sx={{ marginX: 1 }}
        >
          {Array.from({ length: totalPages }, (_, index) => (
            <MenuItem key={index} value={index}>
              Página {index + 1}
            </MenuItem>
          ))}
        </Select>

        <IconButton
          onClick={() => onChangePage({}, page + 1)}
          disabled={page >= totalPages - 1}
          sx={{ marginLeft: 1 }}
        >
          <Iconify icon="eva:arrow-forward-fill" />
        </IconButton>
      </Box>

      <ModalCreateItem
        open={createMaterialModalOpen}
        handleClose={closeCreateMaterialModal}
        reQuery={queryData}
      />
    </>
  );
}

export function applySortFilter({
  tableData,
  comparator,
  filterName,
}: {
  tableData: Item[];
  comparator: (a: any, b: any) => number;
  filterName: string;
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item: Record<string, any>) =>
        item.description.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return tableData;
}