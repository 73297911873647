import * as Yup from 'yup';

export const EditPlanSchema = Yup.object().shape({
  description: Yup.string().required('Campo obrigatório'),
  group_id: Yup.string().required('Campo obrigatório'),
  items: Yup.array().min(1, 'Campo obrigatório'),
});

export const NewPlanSchema = Yup.object().shape({
  description: Yup.string().required('Campo obrigatório'),
  group_id: Yup.string().required('Campo obrigatório'),
  items: Yup.array().min(1, 'Campo obrigatório'),
});
