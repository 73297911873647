const routes = {
  auth: '/auth',
  users: '/users',
  plans: '/plans',
  metrics: '/metrics',
  items: '/items',
  itemBundles: '/item-bundles',
};

const auth = {
  login: () => routes.auth + '/login-admin',
  account: () => routes.auth + '/account',
  changePassword: (id: string) => `${routes.auth}/change-password/${id}`,
};

const users = {
  read: () => `${routes.users}`,
  readUnique: (id: string) => `${routes.users}/${id}`,
  create: () => `${routes.users}`,
  delete: (id: string) => `${routes.users}/${id}`,
  update: (id: string) => `${routes.users}/${id}`,

  uploadLogo: (id: string) => `${routes.users}/uploadLogo/${id}`,
  logo: (id: string) => `${routes.users}/logo/${id}`,
};

const plans = {
  read: () => `${routes.plans}`,
  readUnique: (id: string) => `${routes.plans}/${id}`,
  create: () => `${routes.plans}`,
  delete: (id: string) => `${routes.plans}/${id}`,
  update: (id: string) => `${routes.plans}/${id}`,
};

const metrics = {
  planCount: `${routes.metrics}/planCount`,
  dimensioningCount: `${routes.metrics}/dimensioningCount`,
  projectsCreation: `${routes.metrics}/projectsCreationDate`,
  subscriptionsCreation: `${routes.metrics}/subscriptionsCreationDate`,
  logins: `${routes.metrics}/loginLogDate`,
};

const items = {
  read: (page: number, limit: number, search: string) =>
    `${routes.items}/findAllAdmin/${page}/${limit}/${search}`,
  reading: (search: string) =>
    `${routes.items}/findAllItems/${search}`,
  readUnique: (id: string) => `${routes.items}/${id}`,
  uploadImage: (id: string, formData?: FormData) => `${routes.items}/uploadImage/${id}`,
  selectedImage: (id: string) => `${routes.items}/selectedImage/${id}`,
  readExistingImages: () => `${routes.items}/images`,
  create: () => `${routes.items}`,
  delete: (id: string) => `${routes.items}/${id}`,
  update: (id: string) => `${routes.items}/${id}`,
  readGroups: () => `${routes.items}/getGroups`,

  deactivate: (id: string) => `${routes.items}/deactivate/${id}`,
  activate: (id: string) => `${routes.items}/activate/${id}`,
  updateImageUrl: (id: string) => `${routes.items}/${id}/update-image`
};

const itemBundles = {
  read: (page: number, limit: number, search: string) =>
    `${routes.itemBundles}/findAllAdmin/${page}/${limit}/${search}`,
  readItems: (id: string) => `${routes.itemBundles}/get-items/${id}`,
  readUnique: (id: string) => `${routes.itemBundles}/${id}`,
  create: () => `${routes.itemBundles}`,
  delete: (id: string) => `${routes.itemBundles}/${id}`,
  update: (id: string) => `${routes.itemBundles}/${id}`,

  deactivate: (id: string) => `${routes.itemBundles}/deactivate/${id}`,
  activate: (id: string) => `${routes.itemBundles}/activate/${id}`,
};

export const apiRoutes = {
  auth,
  users,
  plans,
  metrics,
  items,
  itemBundles,
};
