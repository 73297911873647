import { format, addDays } from 'date-fns';

import ReactApexChart, { Props as reactApexChartsProps } from 'react-apexcharts';

interface Props {
  data: string[];
}

export const LoginDateChart = ({ data }: Props) => {
  const dateCounts: { [date: string]: number } = {};

  data.forEach((dateString) => {
    const date = new Date(dateString).toISOString().split('T')[0];
    dateCounts[date] = (dateCounts[date] || 0) + 1;
  });

  const chartData: reactApexChartsProps = {
    options: {
      chart: {
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      xaxis: {
        tickAmount: 4,
        tickPlacement: 'between',
        type: 'datetime',
        categories: Object.keys(dateCounts),
        labels: {
          formatter: function (value, timestamp) {
            return format(addDays(new Date(value + 86400), 1), 'dd/MM/yy');
          },
        },
      },
      yaxis: {
        title: {
          text: 'Contagem',
        },
        decimalsInFloat: 0,
      },
    },
    series: [
      {
        name: 'Acessos',
        data: Object.keys(dateCounts).map((date) => ({
          x: date,
          y: dateCounts[date],
        })),
      },
    ],
  };

  return (
    <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
  );
};
