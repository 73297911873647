import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Stack,
  Link,
  Alert,
  Tooltip,
  Container,
  Typography,
  Button,
  useTheme,
} from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// sections
import { LoginForm } from '../../sections/auth/login';
import palette from 'src/theme/palette';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
    position: 'relative',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  minHeight: '80%',
  height: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '20px',
  borderRadius: '16px 0 0 16px',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '50%',
  maxWidth: '40%',
  margin: 'auto',
  maxHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.up('md')]: {
    width: '50%',
    margin: 0,
  },
}));

// ,

const SectionContainer = styled('div')(({ theme }) => ({
  height: '100%',
}));
const ShadowContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '5vh',
    margin: 'auto',
    width: '80vw',
    maxWidth: '1250px',
    height: '90vh',
    maxHeight: '780px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '75px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;',
    borderRadius: '20px',
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();
  const theme = useTheme();

  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <ShadowContainer>
          <SectionContainer>
            {!mdUp && (
              <HeaderStyle sx={{ display: 'flex', justifyContent: 'center' }}>
                <Image
                  visibleByDefault
                  src="/assets/logo/brc_lightning_blue.png"
                  alt="Logo"
                  sx={{ width: '210px' }}
                />
              </HeaderStyle>
            )}

            {mdUp && (
              <>
                <SectionStyle
                  sx={{
                    background: `linear-gradient(135deg, ${theme.palette.grey[400]} 0%, ${theme.palette.grey[600]}  100%);`,
                  }}
                >
                  <HeaderStyle>
                    <Image
                      visibleByDefault
                      src="/assets/logo/brc_lightning_white.png"
                      alt="Logo"
                      sx={{ width: '300px' }}
                    />
                  </HeaderStyle>
                  <Typography
                    variant="h3"
                    style={{
                      margin: '0 20px',
                      color: `${theme.palette.grey[0]}`,
                      fontSize: '32px',
                      fontWeight: '400',
                      lineHeight: '48px',
                    }}
                  >
                    <span style={{ fontWeight: 'bold' }}>Proteção</span> contra{'\n'}
                    <span style={{ fontWeight: 'bold' }}>descargas atmosféricas</span> {'\n'}
                    na palma de sua mão!
                  </Typography>
                </SectionStyle>
              </>
            )}
          </SectionContainer>
          <ContentStyle sx={{ minWidth: { xs: '80vw', md: '0' } }}>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Área administrativa
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Entre com o seu e-mail.</Typography>
              </Box>
            </Stack>

            <LoginForm />
          </ContentStyle>
        </ShadowContainer>
      </RootStyle>
    </Page>
  );
}
