import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  TableContainer,
  TableBody,
  Table,
  useTheme,
} from '@mui/material';
import axiosInstance from 'src/utils/axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { ItemOnBundle } from 'src/@types/localEntity';
import Iconify from 'src/components/Iconify';
import { apiRoutes } from 'src/routes/api/index';
import useTable from 'src/hooks/useTable';
import Scrollbar from 'src/components/Scrollbar';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TableSelectedActions,
} from 'src/components/table';
import ItemTableRowQuantitySelection from 'src/sections/@dashboard/items/list/ItemTableRowQuantitySelection';

interface ModalViewItemBundleProps {
  open: boolean;
  handleClose: () => void;
  id: string;
}

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'description', label: 'Descrição', align: 'left' },
  { id: 'specification', label: 'Especificação', align: 'left' },
  { id: 'complement', label: 'Complemento', align: 'left' },
  { id: 'reference', label: 'Referência', align: 'left' },
  { id: 'group', label: 'Grupo de materiais', align: 'left' },
  { id: 'unit', label: 'Unid.', align: 'center' },
  { id: '', label: 'Qntd.', align: 'left' },
];
// ----------------------------------------------------------------------
export function ModalViewItemBundle({ open, handleClose, id }: ModalViewItemBundleProps) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const queryItems = async () => {
    const items = await axiosInstance.get(apiRoutes.itemBundles.readItems(id));

    if (items) {
      setTableData(items?.data);
      setTableDataLength(items?.data.length);
    }
  };

  useEffect(() => {
    queryItems();
  }, [open]);

  const {
    page,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const [tableData, setTableData] = useState<ItemOnBundle[]>([]);
  const [tableDataLength, setTableDataLength] = useState(0);
  const [filterName, setFilterName] = useState('');

  // table functions
  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setPage(0);
  };

  const isNotFound = !tableData.length && !!filterName;

  // ItemQuantity

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        {`Materiais do Bloco`}
        <div
          style={{
            borderRadius: '3px',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.grey[300],
            boxShadow: '0px 1px 2px rgba(145, 158, 171, 0.24)',
            cursor: 'pointer',
          }}
          onClick={() => handleClose()}
        >
          <Iconify icon="material-symbols:close" fontSize="10px" color={theme.palette.grey[600]} />
        </div>
      </DialogTitle>
      <DialogContent style={{ marginTop: '20px' }}>
        <Box>
          <Grid container spacing={2} sx={{ marginBottom: '15px' }}>
            <Grid item xs={12} sm={12}>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                  {selected.length > 0 && (
                    <TableSelectedActions
                      numSelected={selected.length}
                      rowCount={tableData.length}
                      onSelectAllRows={(checked) =>
                        onSelectAllRows(
                          checked,
                          tableData.map((row) => row?.id || '')
                        )
                      }
                    />
                  )}

                  <Table size={'small'}>
                    <TableHeadCustom
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />

                    <TableBody>
                      {tableData.map((row) => (
                        <ItemTableRowQuantitySelection
                          key={row.id}
                          row={row.item}
                          quantity={row.quantity || 0}
                          setQuantity={() => {}}
                          isView={true}
                        />
                      ))}

                      <TableEmptyRows emptyRows={rowsPerPage - tableData.length} />

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
