import { useState, useEffect } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  useTheme,
  Switch,
  DialogContent,
  Dialog,
} from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import { Item } from 'src/@types/localEntity';
import { ModalCreateItem } from 'src/pages/dashboard/items/ModalCreateItem';
import axiosInstance from 'src/utils/axios';
import { apiRoutes } from 'src/routes/api';
import { useSnackbar } from 'notistack';
import { ModalConfirmations } from 'src/components/modal/ModalConfirmations';
import Image from 'src/components/Image';

// ----------------------------------------------------------------------

type Props = {
  row: Item;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  reQuery: () => void;
  existingUrls: Item[];
};

export default function ItemTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  reQuery,
  existingUrls,
}: Props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { id, image_url, description, specification, complement, reference, unit, group } = row;
  const [openModalImage, setOpenModalImage] = useState(false);
  const [imageOpen, setImageOpen] = useState('');
  const [tableData, setTableData] = useState<Item[]>([]);
  const [selectedItem, setSelectedItem] = useState<Item | undefined>(undefined);

  const openImage = (image: string | undefined) => {
    if (image) {
      setImageOpen(image);
      setOpenModalImage(true);
    }
  };

  const [createMaterialModalOpen, setCreateMaterialModalOpen] = useState(false);
  const openCreateMaterialModal = () => setCreateMaterialModalOpen(true);
  const closeCreateMaterialModal = () => setCreateMaterialModalOpen(false);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDeleteModal = (item: Item) => {
    setSelectedItem(item);
    setDeleteModalOpen(true);
  };
  const closeDeleteModal = () => setDeleteModalOpen(false);

  const handleDeactivate = async (itemId: string) => {
    await axiosInstance
      .patch(apiRoutes.items.deactivate(itemId))
      .then((data) => {
        enqueueSnackbar('Material desativado com sucesso!');
        reQuery();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
        reQuery();
      });
  };

  const handleActivate = async (itemId: string) => {
    await axiosInstance
      .patch(apiRoutes.items.activate(itemId))
      .then((data) => {
        enqueueSnackbar('Material ativado com sucesso!');
        reQuery();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
        reQuery();
      });
  };

  const handleDeleteRow = async () => {
    if (selectedItem) {
      try {
        await axiosInstance.delete(apiRoutes.items.delete(selectedItem.id));
        enqueueSnackbar('Material removido com sucesso!');
        reQuery();
        closeDeleteModal();
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };
  const handleOpenEditModal = (item: Item) => {
    setSelectedItem(item);
    openCreateMaterialModal();
  };
  const handleCloseEditModal = () => {
    setSelectedItem(undefined);
    closeCreateMaterialModal();
  };

  useEffect(() => {
    const duplicates = existingUrls.filter((item) => item.image_url === row.image_url);
    setTableData(duplicates);
    return;
  }, [existingUrls, row.image_url]);

  return (
    <>
      {tableData.length > 0 && (
        <TableRow selected={selected}>
          <TableCell
            style={{
              cursor: 'pointer',
              width: '100px',
              border: '1px solid #ddd'
            }}
            onClick={() => openImage(image_url)}
          >
            <Image
              src={image_url}
              style={{ width: '70px !important', height: '70px !important' }}
            />
          </TableCell>
          <TableCell align="left" colSpan={6} style={{ border: '1px solid #ddd', padding: '0px' }}>
            <div>
              <table style={{ borderCollapse: 'collapse', width: '90%', tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Descrição</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Especificação</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Complemento</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Referência</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Grupo de materiais</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', width: '66px' }} />
                    <th style={{ border: '1px solid #ddd', padding: '8px', width: '66px' }} />
                    <th style={{ border: '1px solid #ddd', padding: '8px', width: '80px' }} />
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((item, index) => (
                    <tr key={index} style={{
                      borderBottom: '1px solid #ddd',
                      transition: 'background-color 0.3s ease',
                      cursor: 'pointer',
                    }}
                      onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f5f5f5'}
                      onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}>
                      <td style={{ border: '1px solid #ddd', padding: '8px', overflow: 'auto' }}><p>{item.description}</p></td>
                      <td style={{ border: '1px solid #ddd', padding: '8px', overflow: 'auto' }}><p>{item.specification}</p></td>
                      <td style={{ border: '1px solid #ddd', padding: '8px', overflow: 'auto' }}><p>{item.complement}</p></td>
                      <td style={{ border: '1px solid #ddd', padding: '8px', overflow: 'auto' }}><p>{item.reference}</p></td>
                      <td style={{ border: '1px solid #ddd', padding: '8px', overflow: 'auto' }}><p>{item.group?.name}</p></td>
                      <td style={{ border: '1px solid #ddd', padding: '8px', overflow: 'auto' }}>
                        <IconButton onClick={() => handleOpenEditModal(item)}>
                          <Iconify
                            color={theme.palette.primary.main}
                            icon={'eva:edit-fill'}
                            width={24}
                            height={24}
                          />
                        </IconButton>
                      </td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                        <IconButton onClick={() => handleOpenDeleteModal(item)}>
                          <Iconify
                            color={theme.palette.primary.main}
                            icon={'ph:trash-fill'}
                            width={24}
                            height={24}
                          />
                        </IconButton>
                      </td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                        <Switch
                          checked={item.active}
                          onChange={() => {
                            if (item.active) {
                              handleDeactivate(item.id);
                            } else {
                              handleActivate(item.id);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </TableCell>
        </TableRow>

      )}
      <ModalCreateItem
        open={createMaterialModalOpen}
        handleClose={handleCloseEditModal}
        id={selectedItem?.id || ''}
        currentItem={selectedItem}
        reQuery={reQuery}
      />
      <ModalImage
        open={openModalImage}
        image={imageOpen}
        handleClose={() => setOpenModalImage(false)}
      />
      <ModalConfirmations
        title={'Deseja excluir esse material?'}
        description={'Essa ação é definitiva e irreversível, deseja prosseguir?'}
        open={deleteModalOpen}
        handleClose={closeDeleteModal}
        execute={handleDeleteRow}
      />
    </>
  );
}

interface ModalImageProps {
  open: boolean;
  handleClose: () => void;
  image: string;
}

function ModalImage({ open, handleClose, image }: ModalImageProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Image src={image} style={{ width: '80vw' }} />
      </DialogContent>
    </Dialog>
  );
}
