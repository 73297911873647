import * as Yup from 'yup';
// @mui
import { Box, Grid, Card, Stack, Typography, TextField, Button, ButtonBase } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';
// formik
import { useFormik, FormikProvider, Form } from 'formik';
import axios from '../../../../utils/axios';
import { apiRoutes } from 'src/routes/api';
import Iconify from 'src/components/Iconify';
import palette from 'src/theme/palette';
import { useState } from 'react';
import axiosInstance from '../../../../utils/axios';
import Image from 'src/components/Image';
import MyAvatar from 'src/components/MyAvatar';
import { useSnackbar } from 'notistack';
import { HOST_API } from 'src/config';

// ----------------------------------------------------------------------

type FormValuesProps = {
  name: string;
  email: string;
};

export default function AccountGeneral() {
  const { user } = useAuth();
  const [hover, setHover] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [imageUrl, setImageUrl] = useState(`${HOST_API}${apiRoutes.users.logo(user?.id || '')}`);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const arquivoSelecionado = event.target.files?.[0];

    if (arquivoSelecionado) {
      const formData = new FormData();
      formData.append('file', arquivoSelecionado);

      console.log('1', formData);

      await axiosInstance
        .post(`users/uploadLogo/${user?.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((data) => {
          enqueueSnackbar('Imagem alterada com sucesso!', { variant: 'success' });
          window.location.reload();
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error' });
        });
    }
  };

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().email().required('Email é obrigatório'),
  });

  const formik = useFormik<FormValuesProps>({
    initialValues: {
      name: user?.name || '',
      email: user?.email || '',
    },
    validationSchema: UpdateUserSchema,
    onSubmit: async (values: FormValuesProps, { resetForm, setErrors }) => {
      await axios
        .put(apiRoutes.users.update(user?.id), {
          ...values,
        })
        .then((data) => alert('Tudo ok'))
        .catch((error) => {
          console.error(error);

          resetForm();

          // setErrors({ afterSubmit: error.message })
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, isValid, dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 3,
                  columnGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <TextField
                  label="Nome"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Box>

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disabled={!(isValid && dirty)}
                  loading={isSubmitting}
                >
                  Salvar
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                p: 5,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ButtonBase
                component="label"
                sx={{
                  marginBottom: '10px',
                  width: '144px',
                  height: '144px',
                  border: '1px dashed #aaaaaa',
                  borderRadius: '50%',
                  backgroundColor: 'rgba(200, 200, 200, 0.5)',
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                {user?.s3_image_url ? (
                  <Image
                    src={imageUrl}
                    alt="Perfil"
                    style={{
                      objectFit: 'cover',
                      minWidth: '144px',
                      minHeight: '144px',
                    }}
                  />
                ) : (
                  <MyAvatar sx={{ width: '144px', height: '144px' }} />
                )}

                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
                {hover && (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      borderRadius: '50%',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      display: 'flex',
                      backgroundColor: 'rgba(200, 200, 200, 0.5)',
                    }}
                  >
                    <Iconify icon={'ic:outline-add-a-photo'} sx={{ fontSize: 30 }} />
                    <Typography
                      variant="caption"
                      fontWeight={400}
                      display={'block'}
                      mt={0.2}
                    >{`Atualizar Foto`}</Typography>
                  </Box>
                )}
              </ButtonBase>
              <Typography
                variant="caption"
                color={palette.light.grey[600]}
                fontWeight={400}
                display={'block'}
              >{`Permitido *.jpeg, *.jpg, *.png`}</Typography>

              <Typography
                variant="caption"
                color={palette.light.grey[600]}
                fontWeight={400}
                display={'block'}
              >{`Tamanho máximo de 3.1 MB`}</Typography>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
